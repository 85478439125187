// eslint-disable-file
import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { getData } from '../../utils/utilityFunctions';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';

const fadeIn = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  },
  100% {
    transform: scale('100');
    opacity: 1;
  }
`;

const HeaderContainer = styled.div`
  width: 100:
  display: flex;
  flex-direction: row:
  align-items: center;
  padding: 20px 60px 10px;
`;

const LogoContainer = styled.div`
  width: 100%;
  max-width: 250px;
  img {
    width: 100%;
  }
`;

const BodyContainer = styled.div`
  width: calc(100% - 20px);
  max-width: 960px;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ThankYouContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff9ee;
  width: 100%;
  padding: 50px 20px 60px;
  position: relative;

  .preApproveBtn {
    border: none;
    border-radius: 15px;
    width: 100%;
    max-width: 300px;
    padding: 10px 0;
    color: #fff;
    background: #175af7;
    font-weight: 700;
    font-size: 20px;
    animation: ${fadeIn} 0.9s;
    &:hover {
      cursor: pointer;
    }
  }

  .badgeContainer {
    position: absolute;
    max-width: 200px;
    top: -60px;
    right: -40px;
    img {
      width: 100%;
    }
    @media (max-width: 801px) {
      max-width: 108px;
      right: -8px;
    }
  }

  p {
    font-size: 18px;
    width: 70%;
    text-align: center;
    @media (max-width: 801px) {
      width: 100%;
    }
  }

  span {
    font-weight: 700;
  }
`;

const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px 20px 60px;
  text-align: center;
`;

const Reviews = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 801px) {
    flex-direction: column;
  }

  .subContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 50%;
    @media (max-width: 801px) {
      width: 100%;
    }
  }

  .subLogoContainer {
    max-width: 100px;
    height: 114px;
    img {
      height: auto;
      width: 100%;
      margin-top: 8px;
    }
  }
  .subLogoContainer1 {
    max-width: 57px;
    height: 114px;
    img {
      height: auto;
      width: 100%;
    }
  }

  span {
    font-weight: 700;
  }
  .rates {
    height: 30px;
    text-align: center;
  }

  .largerFont {
    font-size: 18px;
  }

  .smallerFont {
    font-size: 15px;
  }
`;

const ReviewLinkContainer = styled.div`
  width: 100%;
  background: #ecf5ff;
  padding: 10px;
  max-width: 40%;
  margin-top: 15px;
  border-radius: 20px;
  @media (max-width: 801px) {
    max-width: 100%;
  }
  p {
    font-size: 14px;
    margin: 0;
    @media (max-width: 801px) {
      font-size: 12px;
    }
  }
`;

const FooterContainer = styled.div`
  width: 100%;
  border-top: 1px solid #000;
  p {
    margin-top: 20px;
    font-size: 12px;
  }
`;

const googleFontLink =
  'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap';

const EvuThankyou = ({ config }) => {
  const [redirectLink, setRedirectLink] = useState('');
  const [clickPix, setClickPix] = useState('');

  const fetchData = async (url, data) => {
    const urlStringData = Object.keys(data)
      .map(key => `${key}=${data[key]}`)
      .join('&');

    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Connection: 'keep-alive',
      },
      body: urlStringData,
    });
    const jsonData = await res.json();

    if (res && jsonData.return_url) {
      setRedirectLink(jsonData.return_url);
      setClickPix(
        `https://trkpls2.com/?ge4=5qi8uLPeLEbs1TXrViHEozHyoULgxPWH&s1=${getData(
          'loan_purpose'
        )}`
      );
    } else {
      setRedirectLink(
        `https://trkpls5.com/?bbz=mz1qEjNPyWlh0TuYPmlXUjHyoULgxPWH&s1=${getData(
          'loan_purpose'
        )}`
      );
      setClickPix(``);
    }
  };

  const trackConversion = trackingUrl => {
    if (trackingUrl && trackingUrl !== '[TRACKING_LINK]') {
      const trackingPixel = document.createElement('img');
      trackingPixel.src = trackingUrl;
      trackingPixel.width = 1;
      trackingPixel.height = 1;
      trackingPixel.style.display = 'none';
      document.body.appendChild(trackingPixel);
    }
  };

  const openUrl = (url, trackingUrl) => {
    trackConversion(trackingUrl);
    window.open(url, '_blank');
  };

  const translateLoanPurpose = () => {
    const loanPurpose = getData('loan_purpose');
    let value = '';
    if (loanPurpose === 'Refinance') {
      value = '1';
    } else {
      value = '2';
    }
    return value;
  };

  const translateCreditRating = () => {
    const creditRating = getData('credit_rating');
    let value = '';
    if (creditRating === 'average') {
      value = 'fair';
    } else {
      value = creditRating;
    }
    return value;
  };

  const translateLoanAmount = () => {
    const loanType = getData('loan_purpose');
    let value = '';
    if (loanType === 'Refinance') {
      value = getData('total_loan_amount');
    } else {
      value = getData('est_purchase_price');
    }
    return value;
  };

  const translateMovingDate = () => {
    const movingDate = getData('moving_date');
    const loanPurpose = getData('loan_purpose');
    const twoToSixMonthValues = ['3-6 Months', 'Within 90 Days'];
    let value = '';
    if (loanPurpose === 'Purchase') {
      if (movingDate === 'Right Away') {
        value = 'within a month';
      } else if (twoToSixMonthValues.includes(movingDate)) {
        value = 'in 2-6 months';
      } else {
        value = 'over 6 months';
      }
    } else {
      value = '';
    }
    return value;
  };

  const translateRefiReason = () => {
    const reason = getData('refi_purpose');
    let value = '';
    if (reason === 'Cash Out') {
      value = 'cashout';
    } else {
      value = 'other';
    }
    return value;
  };

  const translatePropertyType = () => {
    const propertyType = getData('property_type');
    let value = '';
    if (propertyType === 'Single Family') {
      value = 'single';
    } else if (propertyType === 'Townhouse') {
      value = 'townhome';
    } else if (
      propertyType === 'Mobile Home' ||
      propertyType === 'Manufactured'
    ) {
      value = 'manufactured';
    } else {
      value = 'single';
    }
    return value;
  };

  const translateHomeValue = () => {
    const loanPurpose = getData('loan_purpose');
    let value;
    if (loanPurpose === 'Refinance') {
      value = +getData('est_property_value');
    } else {
      value = +getData('est_purchase_price');
    }
    return value;
  };

  const translateCurrentRate = () => {
    const loanPurpose = getData('loan_purpose');
    let value = '';
    if (loanPurpose === 'Refinance') {
      value = +getData('mortgage_rate');
    } else {
      value = '';
    }

    return value;
  };

  const translateMortgageBalance = () => {
    const loanPurpose = getData('loan_purpose');
    let value = '';
    if (loanPurpose === 'Refinance') {
      value = +getData('mortgage_amount');
    } else {
      value = '';
    }

    return value;
  };

  const translatedDownPayment = () => {
    const percentDown = getData('estimated_down_payment');
    const costOfHouse = getData('est_purchase_price');
    const loanPurpose = getData('loan_purpose');

    let downPayment;
    const updateDecimal = () => {
      switch (+percentDown) {
        case 5:
          return 0.05;
        case 10:
          return 0.1;
        case 15:
          return 0.15;
        case 20:
          return 0.2;
        case 25:
          return 0.25;
        default:
          return '';
      }
    };

    if (loanPurpose === 'Purchase') {
      if (percentDown && costOfHouse) {
        downPayment = +costOfHouse * updateDecimal();
      }
    } else {
      downPayment = '';
    }
    return downPayment;
  };

  const translateCashAmount = () => {
    const loanPurpose = getData('loan_purpose');
    let value = '';

    if (loanPurpose === 'Refinance') {
      value = +getData('additional_cash_needed');
    } else {
      value = '';
    }
    return value;
  };

  useEffect(() => {
    const requestData = {
      need_json: 1,
      host_name: config?.scData?.siteName,
      addr_state: getData('state'),
      addr_city: getData('city'),
      address: getData('address'),
      zip_code: getData('zipcode'),
      email: getData('email'),
      first_name: getData('first_name'),
      last_name: getData('last_name'),
      phone_full: +getData('phone_primary'),
      property_state: getData('property_state'),
      value_home: +translateHomeValue(),
      lead_purpose_id: +translateLoanPurpose(),
      submission_type: 'va',
      credit_rating: translateCreditRating(),
      queue_override: '',
      current_rate: translateCurrentRate(),
      mortgage_balance: +translateMortgageBalance(),
      when_loan: translateMovingDate(),
      refi_reason: translateRefiReason(),
      bankruptcy: '',
      desired_loan_amount: +translateLoanAmount(),
      down_payment: translatedDownPayment(),
      military_branch: '',
      phone_full2: +getData('phone_primary'),
      lead_partner_lead_id: '',
      leadid_token: getData('universal_leadid'),
      src: 'rmpex',
      cmp: getData('cpid'),
      adg: getData('affiliate_id'),
      property_type: translatePropertyType(),
      vu_lead_price_paid: 20,
      cash_out_amount: translateCashAmount(),
      organization: 'vu',
      vu_brand: 'vu',
    };

    fetchData(
      'https://www.veteransunited.com/vendor_opq_backend.php',
      requestData
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <HeaderContainer>
        <LogoContainer>
          <img
            src="https://s3.amazonaws.com/sc-dba.com/evu-thankyou/images/logo.png"
            alt="Veteran United Home Loans"
          />
        </LogoContainer>
      </HeaderContainer>
      <BodyContainer>
        <ThankYouContainer>
          <div className="badgeContainer">
            <img
              src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/numberOneBadge.png"
              alt="number 1"
            />
          </div>
          <h1>
            Thanks, {getData('first_name') ? `${getData('first_name')}!` : ''}
          </h1>
          <p>
            You've been connected with Veterans United Home Loans, the{' '}
            <span>Nation's #1 VA Lender.</span> We'll be reaching out shortly.
          </p>
          <p>Let's complete your profile so we can secure your rate.</p>

          {redirectLink && (
            <button
              className="preApproveBtn"
              type="button"
              onClick={() => openUrl(redirectLink, clickPix)}
            >
              Continue &rarr;
            </button>
          )}
        </ThankYouContainer>
        <ReviewContainer>
          <h2>Our mission is great customer service.</h2>
          <Reviews>
            <div className="subContainer">
              <div className="subLogoContainer1">
                <img
                  src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/Foam-Finger-1.png"
                  alt="foam finger"
                />
              </div>
              <div className="rates">
                <p className="largerFont">
                  <span>341,769+</span> Reviews
                </p>
              </div>
              <div className="rates">
                <p className="smallerFont">
                  from Veterans like you...and counting!
                </p>
              </div>
            </div>
            <div className="subContainer">
              <div className="subLogoContainer">
                <img
                  src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/flag-pennant-1.png"
                  alt="flag"
                />
              </div>
              <div className="rates">
                <p className="largerFont">
                  <span>4.8</span> out of <span>5</span>
                </p>
              </div>
              <div className="rates">
                <p className="smallerFont">Average Customer Rating</p>
              </div>
            </div>
          </Reviews>
          <ReviewLinkContainer>
            <p>
              Checkout{' '}
              <a
                href="https://www.veteransunited.com/reviews/"
                target="_blank"
                rel="noreferrer"
              >
                VeteransUnited.com/Reviews
              </a>{' '}
              to see more.
            </p>
          </ReviewLinkContainer>
        </ReviewContainer>
        <FooterContainer>
          <p>
            NMLS #1907. Not a government agency. Equal opportunity lender. The
            choice of a lender should not be based solely on the advertisement.
            Links or references to any products, services, or websites of any
            third part do not constitute or imply any endorsement byu us of
            those third partiew.
          </p>
        </FooterContainer>
      </BodyContainer>
    </>
  );
};

export default withThankyouWrapper(EvuThankyou, googleFontLink);
